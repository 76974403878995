import React from 'react';
import { IMG } from "../const"
import Layout from '../components/layout';
import * as styles from '../assets/styles/404.module.scss';

const NotFoundPage = () => {
  return (
    <Layout pageTitle='404'>
      {() => (
        <div className={styles.errorContainer}>
          <img src={`${IMG.INDEX}/img/error.jpg`} alt="File not found" loading='lazy' width="500" height="512" />
        </div>
      )}
    </Layout>
  )
}

export default NotFoundPage
